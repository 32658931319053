<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.公出变更审批')"
                 fixed
                 right-:text="$t('tbm.取消')"
                 @click-left="$router.go(-1)"
                 @click-right="$router.go(-1)">
    </van-nav-bar>
    <div :style="{paddingTop:'46px',paddingBottom:approvalShow ? '50px' : '0'}"
         class="applyOfficeoutChange">
      <van-collapse v-model="activeNames">
        <van-collapse-item title=""
                           name="1">
          <template #title>
            <div style="display:flex;justify-content:space-between;">
              <span>{{ $t('tbm.原公出信息') }}</span>
              <span :style="{color:ruleForm.formColor,'fontWeight':400}">{{ruleForm.formStatusName}}</span>
            </div>
          </template>
          <div class="applyOfficeoutChangeView">
            <div>
              <div v-for="(item,index) in dataList"
                   :key="index">
                <label>{{item.label}}</label>
                <p>{{ruleForm[item.text]}}</p>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item name="2">
          <template #title>
            <div style="display:flex;justify-content:space-between;">
              <span>{{ $t('tbm.变更信息') }}</span>
            </div>
          </template>
          <div class="applyOfficeoutChangeView">
            <div>
              <div v-for="(item,index) in dataList"
                   :key="index">
                <label>{{item.label}}</label>
                <p>{{changeForm[item.text]}}</p>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div v-if="approvalShow">
        <filesList :showFile="true"
                   v-show="filesList.length>0"
                   :filesList="filesList"></filesList>
        <history-panel :pid="changeForm.id"
                       :woType="procConf.woCode"
                       :formNo="changeForm.formNo"></history-panel>
        <approval v-if="changeForm"
                  class="approvalFixed"
                  :woCodeValue="procConf.woCode"
                  :applyChangeNo="ruleForm.id"
                  saveUrl="applyOfficeout"
                  :businessInfo="changeForm"
                  :detailPageName="$route.name"
                  :customSubmitBtnName="customSubmitBtnName"
                  :processName="procConf.processInstanceName"
                  :taskId="procConf.taskInstanceId">
        </approval>
      </div>
    </div>
  </div>
</template>
<script>
import editComponent from './components/editComponent';
import approvalButton from '@/views/business/components/approvalButton';
import approval from '@/components/approval/approval';
import filesList from '@/views/business/components/filesList';
import historyPanel from '@/views/business/components/historyPanel';
export default {
  components: {
    editComponent,
    approvalButton,
    approval,
    filesList,
    historyPanel
  },
  data () {
    return {
      activeNames: ['2'],
      changeForm: {
        id: '',
        pkOrg: '',
        pkDept: '',
        pkUser: '',
        beginDate: '',
        endDate: '',
        totalDays: '',
        formStatus: '0',
        cancelFlag: '0'
      },
      ruleForm: {
        id: '',
        createTime: '',
        formStatusName: '',
        formColor: '',
        userName: '',
        deptName: '',
        beginDate: '',
        endDate: '',
        totalDays: '',
        reason: '',
        formNo: '',
      },
      dataList: [
        {
          label: '申请人',
          text: 'userName'
        },
        {
          label: '所在部门',
          text: 'deptName'
        },
        {
          label: '开始时间',
          text: 'beginDate'
        },
        {
          label: '结束时间',
          text: 'endDate'
        },
        {
          label: '折合天数',
          text: 'totalDays'
        },
        {
          label: '公出事由',
          text: 'reason'
        }
      ],
      formStatus: [
        { label: '暂存', color: '#318B80' },
        { label: '审核中', color: '#FF7405' },
        { label: '作废', color: '#999999' },
        { label: '通过审核', color: '#4378BE' },
      ],
      applyOfficeoutChangeList: [],
      filesList: [],
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      procConf: {
        businessKey: '',
        processInstanceName: '公出变更',
        woCode: 'TbmApplyOfficeout',
        taskInstanceId: '',
        variables: {},
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
      },
    }
  },
  methods: {
    getFilesList () {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/sysAttchement/list',
        data: {
          'entity': {
            'bizId': this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.filesList = backData
        }
      });
    },
    backFillEditData () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOfficeout/view',
        data: {
          'entity': {
            'id': this.ruleForm.id
          }
        }
      }).then(async res => {
        let backData = res.data.entity;
        if (backData) {
          this.ruleForm = backData;
          this.init();
        }
      });
    },
    init () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOfficeout/getChangeViewList',
        data: {
          'entity': {
            'id': this.ruleForm.pkParent
          }
        }
      }).then((res) => {
        // 目前记录设计只有两条，一条原始 一条变更后的
        if (res.data.ext.length > 0) {
          this.applyOfficeoutChangeList = [];
          for (let i = 0; i < res.data.ext.length; i++) {
            this.applyOfficeoutChangeList.push(res.data.ext[i])
          }
          this.ruleForm = { ...this.applyOfficeoutChangeList[0] };
          this.changeForm = { ...this.applyOfficeoutChangeList[0], id: '' };

          // 详情赋值
          if (this.applyOfficeoutChangeList.length > 1) {
            this.ruleForm = { ...this.applyOfficeoutChangeList[this.applyOfficeoutChangeList.length - 2] };
            this.changeForm = { ...this.applyOfficeoutChangeList[this.applyOfficeoutChangeList.length - 1] };
            this.getFilesList();

            let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
            let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
            let officeoutReason=this.changeForm.reason;
            if(officeoutReason.length>5){
              officeoutReason=officeoutReason.substring(0.,5)+'……'
            }
            this.procConf.processInstanceName =this.procConf.processInstanceName+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.changeForm.totalDays+'天'
            if (this.$route.query.type) {
              this.approvalShow = true;
            }
          }
          this.ruleForm.formStatusName = this.formStatus[this.ruleForm.formStatus * 1].label;
          this.ruleForm.formColor = this.formStatus[this.ruleForm.formStatus].color;
          this.changeForm.formStatusName = this.formStatus[this.changeForm.formStatus].label;
          this.changeForm.formColor = this.formStatus[this.changeForm.formStatus].color;
          this.changeForm.pkParent = this.ruleForm.pkParent

          
        }
      })
    },
  },
  created () {
    this.ruleForm.id = this.$route.query.businessKey;
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.ruleForm.id = this.EhrUtils.isEmpty(this.ruleForm.id) ? row['businessKey'] : this.ruleForm.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    // 获取变更父id
    if (this.ruleForm.id) {
      this.backFillEditData();
    } else if (this.$route.query.pkParent) {

      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOfficeout/getChangeViewList',
        data: {
          'entity': {
            'id': this.$route.query.pkParent
          }
        }
      }).then((res) => {
        // 目前记录设计只有两条，一条原始 一条变更后的
        if (res.data.ext.length > 0) {
          let officeoutList = res.data.ext
          this.applyOfficeoutChangeList = officeoutList;
          this.ruleForm = { ...this.applyOfficeoutChangeList[0] };

          this.changeForm = { ...this.applyOfficeoutChangeList[0], id: '' };
          let newFormNo = this.applyOfficeoutChangeList[officeoutList.length - 1].formNo
          if (newFormNo.indexOf('-V') > -1) {
            let newFormNoArray = newFormNo.split('-V')
            let version = '-V' + (Number(newFormNoArray[1]) + 1) + '.0';
            newFormNo = newFormNoArray[0] + version;
          } else {
            newFormNo = newFormNo + '-V1.0'
          }
          this.changeForm.formNo = newFormNo;

          //将上一版的请假类型、请假日期设置到本次变更
          this.changeForm.totalDays = res.data.ext[res.data.ext.length - 1].totalDays;
          // this.selectTime.push(res.data.ext[res.data.ext.length - 1].beginDate);
          // this.selectTime.push(res.data.ext[res.data.ext.length - 1].endDate);
          this.changeForm.beginDate = res.data.ext[res.data.ext.length - 1].beginDate;
          this.changeForm.endDate = res.data.ext[res.data.ext.length - 1].endDate;
          this.changeForm.applyOfficeoutDetails = res.data.ext[res.data.ext.length - 1].applyOfficeoutDetails;
          this.changeForm.applyOfficeoutDetails.forEach(res => {
            res.id = '';
          })
          this.changeForm.formStatus = '0';
        }

        // 详情赋值
        if (this.applyOfficeoutChangeList.length > 1) {
          this.ruleForm = { ...this.applyOfficeoutChangeList[this.applyOfficeoutChangeList.length - 2] };
          this.changeForm = { ...this.applyOfficeoutChangeList[this.applyOfficeoutChangeList.length - 1] };
          this.getFilesList();
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
        this.ruleForm.formStatusName = this.formStatus[this.ruleForm.formStatus * 1].label;
        this.ruleForm.formColor = this.formStatus[this.ruleForm.formStatus].color;
        this.changeForm.formStatusName = this.formStatus[this.changeForm.formStatus].label;
        this.changeForm.formColor = this.formStatus[this.changeForm.formStatus].color;
        this.changeForm.pkParent = this.$route.query.pkParent

        let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
        let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
        let officeoutReason=this.changeForm.reason;
        if(officeoutReason.length>5){
          officeoutReason=officeoutReason.substring(0.,5)+'……'
        }
        this.procConf.processInstanceName =this.procConf.processInstanceName+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.changeForm.totalDays+'天'
      })
    }

  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "approvalPanel") {
      next();
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },
}
</script>
<style>
.applyOfficeoutChangeView > div:nth-child(1) {
  background: #fff;
}
.applyOfficeoutChangeView > div:nth-child(1) > div {
  height: 40px;
  display: flex;
  align-items: center;
}
.applyOfficeoutChangeView > div:nth-child(1) > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 14px;
  width: 70px;
}
.applyOfficeoutChangeView > div:nth-child(1) > div p {
  color: rgb(102, 102, 102);
  font-size: 14px;
}

.applyOfficeoutChange .van-collapse-item__content {
  padding: 0 5px;
}
.applyOfficeoutChange .van-collapse-item__title .van-cell__title,
.applyOfficeoutChange .van-collapse-item__title--disabled .van-cell__title {
  color: rgb(102, 102, 102);
  font-weight: 600;
}
</style>
